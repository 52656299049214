@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Philosopher&display=swap');

html {
  font-family: 'Philosopher';
  color: #141823;
}

.secondary-font {
  font-family: 'IBM Plex Sans', sans-serif;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.circle-img {
  border-radius: 50%;
}
